<template>
  <div class="container">
    <div class="header">
      <p class="name">{{ applyInfo.infusionCenterName }}</p>
      <p class="medicine info">
        药品：{{ applyInfo.productName }} ({{ applyInfo.usualName }})X
        {{ applyInfo.productNum }}
      </p>
      <p class="time info">
        预约输注时间：{{
          applyInfo.infusionDate && applyInfo.infusionDate.split(' ')[0]
        }}&nbsp;{{ applyInfo.infusionWeek }}&nbsp;{{ applyInfo.infusionTime }}
      </p>
    </div>

    <div class="question_list">
      <van-form ref="questionRef">
        <div class="question">
          <van-field
            name="doneFlag"
            label="1.您是否已完成输注？（单选）"
            required
            :rules="[{ required: true, message: '请填写问题1' }]"
          >
            <template #input>
              <van-radio-group
                v-model="doneFlag"
                :checked-color="themeColor"
                :disabled="disabled"
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>

        <div class="question">
          <van-field
            name="comprehensiveEvaluate"
            label="2.请您对此次预约输注的做综合评价？（单选）"
            :required="requiredOtherField"
            :rules="[{ required: requiredOtherField, message: '请填写问题2' }]"
          >
            <template #input>
              <van-radio-group
                v-model="comprehensiveEvaluate"
                :checked-color="themeColor"
                :disabled="disabled"
              >
                <van-radio name="0">好评</van-radio>
                <van-radio name="1">一般</van-radio>
                <van-radio name="2">差评</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>

        <div class="question">
          <van-field
            name="environmentEvaluate"
            label="3.您认为输注中心的环境如何？（单选）"
            :required="requiredOtherField"
            :rules="[{ required: requiredOtherField, message: '请填写问题3' }]"
          >
            <template #input>
              <van-radio-group
                v-model="environmentEvaluate"
                :checked-color="themeColor"
                :disabled="disabled"
              >
                <van-radio name="0">满意</van-radio>
                <van-radio name="1">一般</van-radio>
                <van-radio name="2">不满意</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>

        <div class="question">
          <van-field
            name="serviceEvaluate"
            label="4.您认为输注中心的医护服务如何？（单选）"
            :required="requiredOtherField"
            :rules="[{ required: requiredOtherField, message: '请填写问题4' }]"
          >
            <template #input>
              <van-radio-group
                v-model="serviceEvaluate"
                :checked-color="themeColor"
                :disabled="disabled"
              >
                <van-radio name="0">满意</van-radio>
                <van-radio name="1">一般</van-radio>
                <van-radio name="2">不满意</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>

        <div class="question">
          <van-field
            name="reasonForSelections"
            label="5.请选择您预约输注中心的原因？（多选）"
            :required="requiredOtherField"
            :rules="[{ required: requiredOtherField, message: '请填写问题5' }]"
          >
            <template #input>
              <van-checkbox-group
                v-model="reasonForSelections"
                :checked-color="themeColor"
                :disabled="disabled"
              >
                <van-checkbox name="1">距离较近</van-checkbox>
                <van-checkbox name="2">环境较好</van-checkbox>
                <van-checkbox name="3">价格实惠</van-checkbox>
                <van-checkbox name="4">服务友好</van-checkbox>
                <van-checkbox name="5">其他原因</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>

          <div class="area_wrapper" v-if="reasonForSelections.includes('5')">
            <textarea
              class="reason_detail"
              placeholder="请填写详细原因"
              v-model="reasonForSelectionRemark"
              maxlength="20"
            ></textarea>
          </div>
        </div>

        <div class="question suggestion">
          <van-field
            name="comment"
            label="6.请留下您对诊所或平台的建议"
            :rules="[{ required: false, message: '请填写问题6' }]"
          >
            <template #input>
              <div class="area_wrapper">
                <textarea
                  class="reason_detail"
                  placeholder="请输入平台建议"
                  v-model="comment"
                ></textarea>
              </div>
            </template>
          </van-field>
        </div>
      </van-form>
    </div>

    <!-- 底部操作按钮 -->
    <bottom-btns
      v-if="!disabled"
      :btns="[
        {
          text: '提交',
          type: 'primary',
          eventName: 'handleSubmit',
        },
      ]"
      @handleSubmit="handleClickSubmit"
    />
  </div>
</template>

<script>
import InfusionCenterItem from '../../components/infusion-center-item/index.vue';
import BottomBtns from '@/components/bottom-btns/index.vue';
import api from '@/api'
import { Toast } from 'vant'
export default {
  name: 'search-center',
  components: {
    InfusionCenterItem,
    BottomBtns
  },
  data () {
    return {
      disabled: false,
      queryObj: {},
      applyInfo: {},
      themeColor: '#F36218',
      doneFlag: '',
      comprehensiveEvaluate: '',
      environmentEvaluate: '',
      serviceEvaluate: '',
      reasonForSelections: [], // 多选
      reasonForSelectionRemark: '',
      comment: '',
      requiredOtherField: false,
      comment: ''
    };
  },

  created () {
    this.queryObj = this.getQueryStringArgs()
    this.getDetail()

    if (+this.queryObj.fill === 1) {
      // 填写过了
      this.disabled = true
      this.getSurveyDetail()
    }
  },

  watch: {
    doneFlag (newV) {
      if (+newV === 1) {
        // 完成
        this.requiredOtherField = true
      } else {
        this.requiredOtherField = false
      }
    }
  },
  methods: {
    async getSurveyDetail () {
      try {
        const res = await api.surveyDetail({
          infusionReservationId: this.queryObj.infusionReservationId
        })

        if (res.code === '0') {
          console.log('getSurveyDetail', res.data);
          const { comment, comprehensiveEvaluate, doneFlag, environmentEvaluate, reasonForSelectionRemark, reasonForSelections, serviceEvaluate } = res.data
          this.comment = comment
          this.comprehensiveEvaluate = String(comprehensiveEvaluate)
          this.doneFlag = String(doneFlag)
          this.environmentEvaluate = String(environmentEvaluate)
          this.reasonForSelectionRemark = reasonForSelectionRemark
          this.reasonForSelections = reasonForSelections
          this.serviceEvaluate = String(serviceEvaluate)
        }
      } catch (error) {

      }
    },
    // 获取url query参数方法
    getQueryStringArgs () {
      const qs = location.search.length > 0 ? location.search.substring(1) : ''
      const args = {};
      const arr = qs.split('&').map((kv) => kv.split('='));
      arr.forEach((item) => {
        args[item[0]] = item[1];
      });
      return args;
    },

    // 获取预约详情
    async getDetail () {
      const res = await api.centerApplyDetail({
        id: this.queryObj.id
      })

      if (res.code === '0') {
        this.applyInfo = res.data
      }
    },

    async handleClickSubmit () {
      try {
        await this.$refs.questionRef.validate()
        const submitRes = await api.surveySave({
          "comment": this.comment,
          "comprehensiveEvaluate": this.comprehensiveEvaluate,
          "doneFlag": this.doneFlag,
          "environmentEvaluate": this.environmentEvaluate,
          "infusionCenterCode": this.applyInfo.infusionCenterCode,
          "infusionReservationId": this.applyInfo.id,
          "reasonForSelectionRemark": this.reasonForSelectionRemark,
          "reasonForSelections": this.reasonForSelections,
          "serviceEvaluate": this.serviceEvaluate
        })

        if (submitRes.code === '0') {
          Toast.success('提交成功')
          this.$router.push('/home')
        }
      } catch (error) {
        console.log('valid err', error)
      }

    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  .header {
    margin-bottom: 8px;
    width: 100%;
    padding: 22px 16px 18px 16px;
    box-sizing: border-box;
    background: #fff;

    .name {
      // width: 200px;
      height: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #3a3a3a;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }

    .info {
      // width: 262px;
      height: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      text-align: left;
      font-style: normal;
    }

    .medicine {
      margin-top: 8px;
    }

    .time {
      margin-top: 4px;
    }
  }

  .question_list {
    padding-bottom: 100px;
    .question {
      margin-bottom: 8px;
      background: #fff;

      .title {
        padding: 11px 16px 5px 16px;

        height: 22px;
        font-weight: 500;
        font-size: 16px;
        color: #3a3a3a;
        line-height: 22px;
        font-style: normal;
      }

      /deep/ .van-radio {
        margin-bottom: 12px;
      }

      /deep/ .van-checkbox {
        margin-bottom: 12px;
      }

      .area_wrapper {
        padding: 0 12px;
      }
      .reason_detail {
        border: none;
        width: 100%;
        height: 130px;
        background: #f5f7fa;
        border-radius: 6px;
        padding: 9px 12px;
        box-sizing: border-box;
        font-size: 16px;
      }
    }

    .suggestion {
      /deep/ .van-field__body {
        display: block;
        flex: none;
        .van-field__control {
          display: block;
          flex: none;
          .area_wrapper {
            padding: 0;
          }
        }
      }
    }
  }

  /deep/ .van-cell {
    display: block;
    .van-cell__title {
      display: block;
      flex: none;
      width: 100%;
    }

    .van-cell__value {
      display: block;
      flex: none;
      width: 100%;
    }
  }
}
</style>