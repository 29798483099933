<template>
  <div class="container" @click="handleDetail">
    <img class="img" :src="infusionObj.infusionImage[0]" alt="" />
    <div class="right">
      <span class="name">{{ infusionObj.infusionCenterName }}</span>
      <span v-if="infusionObj.distance" class="distance">{{
        Number(infusionObj.distance) > 1000
          ? `${Number(infusionObj.distance / 1000).toFixed(2)}km`
          : `${infusionObj.distance}m`
      }}</span>
      <p class="tag_line">
        <button
          class="gongli tag"
          v-if="Number(infusionObj.infusionNatrue) === 0"
        >
          公立
        </button>
        <button
          class="gongli tag"
          v-if="Number(infusionObj.infusionNatrue) === 1"
        >
          民营
        </button>
        <button class="yibao tag" v-if="Number(infusionObj.medicareType) === 1">
          医保
        </button>
        <button class="yibao tag" v-if="Number(infusionObj.medicareType) === 0">
          自费
        </button>
      </p>
      <span class="address"
        >{{ infusionObj.infusionAddress
        }}{{ infusionObj.infusionAddressRemark || '' }}</span
      >
      <button class="reserve" @click.stop="apply">立即预约</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'infusion-center-item',
  data () {
    return {};
  },
  props: {
    infusionObj: {
      required: true,
      default: {},
      type: Object,
    },
  },
  methods: {
    apply () {
      this.$emit('apply', this.infusionObj)
    },
    handleDetail () {
      this.$emit('detail', this.infusionObj)
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  padding: 13px 12px 13px 14px;
  margin-bottom: 8px;
  //   width: 348px;
  box-sizing: border-box;
  // height: 92px;
  background: #ffffff;
  border-radius: 6px;

  .img {
    width: 66px;
    height: 66px;
    // background: #ECEDFA;
    border-radius: 3px;
  }

  .right {
    position: relative;
    top: -3px;
    flex: 1;
    padding-left: 8px;
    .name {
      display: inline-block;
      max-width: 190px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .distance {
      position: absolute;
      top: 0;
      right: 0;

      // width: 47px;
      height: 17px;
      font-size: 12px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
    }

    .tag_line {
      margin-top: 4px;
      .tag {
        width: 34px;
        height: 18px;
        margin-right: 8px;
        padding: 0;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #fe6814;
        text-align: center;

        //   width: 25px;
        // height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #fe7125;
        line-height: 17px;
      }
    }

    .address {
      display: inline-block;
      margin-top: 6px;
      max-width: 148px;
      // height: 34px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }

    .reserve {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 63px;
      height: 22px;
      padding: 0;
      background: #f36218;
      border-radius: 50px;

      // width: 48px;
      // height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      border: none;
    }
  }
}
</style>