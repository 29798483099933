<template>
  <div :class="[slot ? 'slot_wrapper' : '', 'bottom']">
    <!-- 插槽 -->
    <slot></slot>
    <div class="btn_wrapper">
      <button
        v-for="(btn, key) in btns"
        :class="{
          plain: btn.type === 'plain',
          disabled: btn.disabled === true,
        }"
        @click="$emit(btn.eventName)"
        class="btn"
      >
        {{ btn.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBtns',
  props: {
    btns: {
      required: true,
      type: Array,
      default () {
        return [
          {
            text: '预约',
            type: 'primary',
            eventName: 'yuyue',
          },
        ];
      },
    },
    // 是否使用插槽，用于设置样式
    slot: {
      required: false,
      type: Boolean,
      default () {
        return false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  position: fixed;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 18px;
  margin: 0 -9.5px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 60px;
  text-align: center;
  line-height: 60px;
  box-shadow: 0px 0px 8px 0px rgba(60, 106, 240, 0.1);
  margin-bottom: env(safe-area-inset-bottom);

  &::after {
    content: '';
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
    z-index: -1;
    background-clip: padding-box; /* 保证背景颜色不会超出元素边框 */
  }

  .btn_wrapper {
    display: flex;
    justify-content: flex-start;
    .btn {
      display: block;
      flex: 1;
      flex-shrink: 0;
      margin: 0 9.5px;
      height: 44px;
      background: #f36218;
      border-radius: 40px;
      border: none;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      &.plain {
        background: #fff;
        color: #f36218;
        border: 1px solid #f36218;
      }
      &.disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border: 1px solid #d9d9d9;
        pointer-events: none;
      }
    }
  }
}

.slot_wrapper {
  height: 94px !important;
  line-height: 94px !important;
}
</style>
